<script>
  /**
   * An item inside a `BasePriceBreakdown` component.
   */
  export default {
    inject: {
      priceBreakdown: {
        from: 'BasePriceBreakdown',
        default: undefined,
      },
    },
    props: {
      /** The label of this item, which appears on the left. */
      label: {
        type: String,
        required: true,
      },
      /** The sublabel of this item, which appears below the label. */
      sublabel: {
        type: String,
        default: undefined,
      },
      /** The price of this item BEFORE discount, which is striked out and appears to the left of the price. */
      beforePrice: {
        type: Number,
        default: undefined,
      },
      /** The price of this item, which appears on the right. */
      price: {
        type: Number,
        default: undefined,
      },
      /** Whether the price of this item is currently being calculated. */
      calculating: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      priceBreakdownCalculating() {
        return this.priceBreakdown?.calculating ?? false
      },
      finalCalculating() {
        return this.calculating || this.priceBreakdownCalculating
      },
      formattedPrice() {
        if (this.finalCalculating) {
          return 'Calculating...'
        }
        if (this.price === undefined) {
          return '-'
        }
        return this.$formatPrice(this.price, false)
      },
      formattedBeforePrice() {
        if (this.finalCalculating) {
          return ''
        }
        return this.$formatPrice(this.beforePrice, false)
      },
    },
  }
</script>

<template>
  <div class="flex justify-between">
    <dt class="mb-2 overflow-hidden ellipsis">
      {{ label }}
      <div v-if="$scopedSlots.icon" class="inline-block align-middle">
        <!-- @slot Icon next to the label. -->
        <slot name="icon" />
      </div>
      <br />
      <span v-if="sublabel" class="text-3xs text-primary-dark">{{ sublabel }}</span>
    </dt>
    <dd class="ml-4 flex-shrink-0 text-right tracking-wide">
      <s v-if="beforePrice" class="pr-1">{{ formattedBeforePrice }}</s>
      {{ formattedPrice }}
    </dd>
  </div>
</template>

<style lang="postcss" scoped>
  .item:not(:first-child) {
    @apply mt-2;
  }
</style>
